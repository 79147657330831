<template>
  <div class="page-box">
    <base-screen>
      <a-form-model layout="inline" :model="query">
        <a-form-model-item label="结算时间" prop="settleRange">
          <date-range-field :f="settleRangeField" v-model="query.settleRange" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>

    <div class="two-col">
      <div class="rank-tab">
        <h3>排名</h3>
        <a-button class="tab-item" :class="{selected: tab === 0}" type="link" @click="tab = 0">自营商品收入排名</a-button>
        <a-button class="tab-item" :class="{selected: tab === 1}" type="link" @click="tab = 1">分销商品收入排名</a-button>
      </div>

      <div class="rank-main">
        <rank-table-goods v-if="tab === 0" :type="tab" :query="query" :hash="hash" :key="tab" />
        <rank-table-goods v-else-if="tab === 1" :type="tab" :query="query" :hash="hash" :key="tab" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api'
import DRF from './DateRangeField'
import { DateRangeField } from './report'
import RankTableGoods from './RankTableGoods'

export default {
  name: 'PageRankGoodsIncome',
  components: {
    DateRangeField: DRF,
    RankTableGoods,
  },
  setup (props) {
    const query = reactive({
      settleRange: []
    })

    const tab = ref(0)
    const hash = ref(0)
    function handleQuery () {
      hash.value++
    }

    function handleReset () {
      query.settleRange = []
      handleQuery()
    }

    return {
      settleRangeField: new DateRangeField({
        type: 'daterange',
        key: 'settleRange',
        title: '结算日期',
      }),
      tab,
      query,
      handleQuery,
      handleReset,
      hash,
    }
  }
}
</script>

<style lang="less" scoped>
.two-col {
  display: flex;
}
.rank-main {
  flex: 1;
}
.rank-tab {
  flex: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 3px;
  margin-right: 20px;
}
.tab-item {
  padding: 0;
  color: #999;
  margin-top: 10px;
}
.tab-item.selected {
  color: @main-color;
}
</style>

<template>
  <div>
    <div class="d-flex pb-1">
      <div class="flex-1">
        <a-radio-group v-model="by">
          <a-radio-button :value="0">按订单金额</a-radio-button>
          <a-radio-button :value="1">按交易数量</a-radio-button>
        </a-radio-group>
      </div>

      <a-button :loading="exporting" @click="exportFn">导出</a-button>
    </div>

    <base-table
      :data-source="data"
      :pagination="page"
      row-key="productIncomeId"
      :columns="columns"
      :loading="loading"
      size="small"
    ></base-table>
  </div>
</template>

<script>
import { onMounted, ref, shallowReactive, watch } from '@vue/composition-api'
import { capital } from '@/api'
import { useExport } from '@/utils/tools'
export default {
  name: 'RankTableGoods',
  props: {
    query: Object,
    hash: Number,
    type: Number, // 0 自营 1 分销
  },
  setup (props) {
    const by = ref(0)
    const page = shallowReactive({
      current: 1,
      size: 10,
      total: 0
    })
    const data = ref([])
    const loading = ref(false)
    onMounted(() => {
      watch(() => props.hash, getData, { immediate: true })
    })

    watch(by, () => {
      page.current = 1
      getData()
    })

    async function getData () {
      loading.value = true
      const res = await (props.type === 0 ? capital.getSelfGoodsIncomeRank : capital.getThirdGoodsIncomeRank)({
        ...props.query,
        sortRule: by.value,
        current: page.current,
        size: page.size
      })
      loading.value = false
      data.value = res.data.map((x, i) => ({
        ...x,
        rank: (res.page.pageNum - 1) * res.page.pageSize + i + 1
      }))
      page.total = res.page.total
    }

    const columns = [
      {
        title: '排名',
        dataIndex: 'rank'
      },
      {
        title: '商品名称',
        dataIndex: 'productName'
      },
      {
        title: '交易数量',
        dataIndex: 'sale'
      },
      {
        title: '订单金额',
        dataIndex: 'settleAmount',
      },
      {
        title: '运费',
        dataIndex: 'logisticsAmount',
      },
    ]

    const { exporting, exportFn } = useExport(() => (props.type === 0 ? capital.exportSelfGoodsIncomeRank : capital.exportThirdGoodsIncomeRank)({ ...props.query, sortRule: by.value }))

    return {
      by,
      data,
      loading,
      page,
      columns,
      exporting,
      exportFn,
    }
  }
}
</script>

<style lang="css" scoped>
.d-flex {
  display: flex;
  align-items: center;
}
.flex-1 {
  flex: 1;
}
.pb-1 {
  padding-bottom: 1em;
}
</style>
